<template>
  <div :class="{ 'c-dark-theme': true, animated: false, fadeIn: true }">
    <BCard style="min-width:440px;max-width:500px" border-variant="dark">
      <div slot="header">
        <!--        <BIconOctagonFill  class="mr-1"/>-->
        <BBadge :variant="signalColor"> {{tank.tid}} </BBadge>
        <BBadge :variant="signalColor" class="ml-1 mr-1" style="min-width:100px"> {{tank.name}} </BBadge>
        <BBadge class="mr-1" :style="{backgroundColor: oilColors[tank.oilCode], color: '#222222'}">
          {{ oilCodes[tank.oilCode] }}
        </BBadge>
        <BBadge :variant="statusVariant" :class="{ blink: (sts!=='N' && sts!=='F') }">{{statusMsg}}</BBadge>
        <small class="float-right"> {{ toShortTime(tankSts.ioAt) }} </small>


        <div class="card-header-actions">
          <!--
          <BButtonGroup size="sm" v-if="inout">
            <BButton class="mr-1" variant="outline-danger" @click="setInout('I')" :disabled="!inout.sts || inout.sts==='O'">
              {{ inout.sts==='I'?`입고종료`:`입고시작` }}
            </BButton>
            <BButton variant="outline-warning" @click="setInout('O')" :disabled="!inout.sts || inout.sts==='I'">
              {{ inout.sts==='O'?`출고종료`:`출고시작` }}
            </BButton>
          </BButtonGroup>
          -->

        </div>
      </div>


      <BRow v-if="inout.sts!=='N'" class="mb-2">
        <BCol class="p-0 ml-1 text-center">
          <BBadge variant="danger" class="mr-2">
            <strong>{{ trans(diffOvm, 'v', volUnit) }}</strong> <BBadge>{{ unitSign(volUnit) }}</BBadge>
          </BBadge>

          <BBadge size="sm" variant="primary"  class="mr-2">
            {{ inout.eqnm }} <strong :class="{blink: true}"> [{{ inout.eqid }}] </strong>
          </BBadge>

          <BBadge size="sm" variant="info" v-show="inout.isDone">
            {{ MdcdMap[inout.mdcd] }} <strong :class="{blink: true}">{{ inout.ano }} </strong>
          </BBadge>

        </BCol>
      </BRow>


      <BRow>
        <BCol class="p-0 mb-1 text-center">
          <BButtonGroup size="sm">
            <BButton variant="info" @click="toggleUnit">용량 <BBadge>{{ unitSign(volUnit) }}</BBadge> </BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:80px" >
              <b>{{ trans(tank.tankRealVol, 'v', volUnit) }}</b>
            </BButton>
            <BButton variant="info">유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:80px">
              <b>{{ trans(pk.ovm, 'v', volUnit) }}</b>
            </BButton>
            <BButton variant="info">충족율 </BButton>
            <BButton variant="outline-info" class="text-right" style="min-width:60px">
              {{ comma(pk.tvp,0) }} %
            </BButton>
          </BButtonGroup>
          <!--// 1-넘침, 2-부족, 3-화재, 4-수분, 5-누유, 6-넘침2, 7-부족2, 8-센서오류, 9-패킷오류-->
          <WidgetEvents class="mt-1" :events="events" :key="eventKey"/>

        </BCol>
      </BRow>


      <BRow>
        <BCol cols="7" ref="container" class="p-0"></BCol>
        <BCol cols="5">
          <BTableSimple small class="float-right small text-nowrap text-right font-weight-bolder">
            <BTr>
              <BTd style="min-width:50px">유위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
              <BTd style="min-width:70px"><BBadge :variant="colors.text.oil[status]">{{ trans(pk.ohr,'l',lenUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>수위 <BBadge>{{ unitSign(lenUnit) }}</BBadge></BTd>
              <BTd><BBadge :variant="colors.text.wtr[status]">{{ trans(pk.whr,'l',lenUnit) }}</BBadge> </BTd>
            </BTr>
            <BTr>
              <BTd>측정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd> <BBadge :variant="colors.text.oil[status]">{{ trans(pk.ovm, 'v', volUnit) }}</BBadge> </BTd>
            </BTr>
            <BTr>
              <BTd>환산유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge>{{ trans(pk.ovc,'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>보정유량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge>{{ trans(pk.ovcr,'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>수분량 <BBadge>{{ unitSign(volUnit) }}</BBadge></BTd>
              <BTd><BBadge> {{ trans(pk.wv, 'v', volUnit) }}</BBadge></BTd>
            </BTr>
            <BTr>
              <BTd>평균 <BBadge>{{ unitSign(tmpUnit) }}</BBadge></BTd>
              <BTd><BBadge> {{ trans(pk.avgTm, 't', tmpUnit) }} </BBadge></BTd>
            </BTr>
            <BTr>
              <BTd colspan="2">
                <BButtonGroup>
                  <BButton @click="toggleDetail" class="small" size="sm" variant="outline-info">온도센서</BButton>
                  <BButton @click="toggleUnit" class="small ml-2" size="sm" variant="outline-info">단위변경</BButton>
                </BButtonGroup>
              </BTd>
<!--              <BTd>-->
<!--                <BBadge>{{ pk.useTms.toString() }}</BBadge>-->
<!--              </BTd>-->
            </BTr>
          </BTableSimple>
        </BCol>
      </BRow>
      <BCard v-show="detailTmShow" no-body>
        <BTableSimple small class="small text-nowrap">
          <BTr class="text-center">
            <BTd>온도1</BTd>
<!--            <BTd>온도7</BTd>-->
<!--            <BTd>온도8</BTd>-->
<!--            <BTd>온도9</BTd>-->
          </BTr>
          <BTr class="text-center font-weight-bolder">
            <BTd>{{ pk.tm1 }}</BTd>
          </BTr>
        </BTableSimple>
        <BTableSimple small class="small text-nowrap">
          <BTr class="text-center"><BTd>평균온도(C)</BTd><BTd>평균온도(F)</BTd><BTd>최고온도</BTd><BTd>최저온도</BTd></BTr>
          <BTr class="text-center font-weight-bold">
            <BTd>{{ pk.avgTm }}</BTd>
            <BTd>{{ pk.avgTmF }}</BTd>
            <BTd>{{ pk.maxTm }}</BTd>
            <BTd>{{ pk.minTm }}</BTd>
          </BTr>
        </BTableSimple>
      </BCard>
    </BCard>
  </div>
</template>

<style>
.tooltip .tooltip-inner {
  text-align: left;
  max-width: 350px !important;
  width: 250px !important;
}

@keyframes blink-effect {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink-effect 1s step-end infinite;

  /*
  animation-name: blink-effect;
  animation-duration: 1s;
  animation-iteration-count:infinite;
  animation-timing-function:step-end;
  */
}


</style>

<script>
//------------------------------------------------------------------------
// import '../../common/HelperMixin';
import WidgetEvents from '../widget/WidgetEvents'
import * as THREE from 'three';
// import {CSG} from 'three-csg-ts';
import {PacketSchema} from "@/common/schema";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
// import moment from 'moment';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {IoStsMap, IoStsVariant, MdcdMap} from '@/common/constants';

import {
  getHexColor,
  getR,
  clone,
  comma,
  trans,
  apiCall, unitSign, random, speech
} from "@/common/utils";
import {gsap} from "gsap";

const PI = 3.14159265359;


const _normalBadge = {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'};
const GL2LTR = 3.78541;
const LTR2GL = 0.264172;

export default {
  name: "TankLorry",
  components: {
    WidgetEvents
  },
  props: {
    width: {type: Number, default: 300},
    height: {type: Number, default: 200},
    tank: { type: Object, default: null },
    bgColor: { type: String, default: '#FFFFFF'},
    tlgPacket : { type: Object, default: null },
    initPacket : { type: Object, default: null }
  },
// °C, °F , ㎜, in, gal ====> C,F,mm,in,gal
  data () {
    return {
      MdcdMap,
      tankSts: { io: '', ioAt: null },
      windowHeight: 0,
      windowWidth: 0,

      maxW: 5,
      maxH: 4,
      maxD: 14,
      baseY: 6,
      baseZ: -5,


      detailTmShow: false,

      eventKey: 0,
      statusMsg: 'wait.',
      statusVariant: 'dark',
      tid: '',
      inout: { sts: 'N' },
      sts: '',

      events: {data: [], dt: null},
      // eventTip: {},
      // eventLogs: [],
      trans: trans,
      unitSign: unitSign,
      volUnit: 'l', // <--> g
      lenUnit: 'mm', // <--> i
      tmpUnit: 'c', // <---> f
      comma: comma,
      // 1-넘침,2-부족,3-화재,4-수분,5-누유,6-넘침2,7-부족2,8-센서오류,9-패킷오류
      eventColor: {
        '1':'warning',
        '2':'warning',
        '3':'danger',
        '4':'info',
        '5':'danger',
        '6':'danger',
        '7':'danger',
        '8':'info',
        '9':'warning'
      },
      badgeColor: clone(_normalBadge),

      status: 'off', // norm, warn, info, off
      signalColor: 'dark',
      pk: clone(PacketSchema),
      w: 400,
      h: 250,
      cube: null,
      renderer: null,
      scene: null,
      camera: null,
      isPacketFirst: true,
      isCamMoved: false,

      controls: null,

      oilColors: this.$store.state.codeMaps['OIL_COLOR'],
      oilCodes: this.$store.state.codeMaps['OIL_CODE'],
      nozSizeMap: this.$store.state.codeMaps['NOZ_SIZE'],
      shapeMap: this.$store.state.codeMaps['TANK_SHAPE'],
      eventCodeMap: this.$store.state.codeMaps['EVENT'],

      machine: null,
      container: null,


      tankColor: null,
      colors: {
        pipe: '#F0F0F0',
        water: '#0000F0',
        sensor: {
          off: '#606050',
          on: '#16A2B8',
          warn: '#FFC008',
          info: '#047BFF',
          danger: '#DC3545',
          error: '#FF0000',
          unknown: '#FF00FF'
        },
        tm: {
          off: '#001233',
          on: '#FF00FF',
          danger: '#DC3545',
          error: '#FF0000',
          unknown: '#FF00FF',
        }, // 온도계 온도

        text: {
          oil: { norm:'success', warn:'danger', info: 'info' },
          wtr: { norm:'secondary', warn:'primary', info: 'info' },
          in : 'warning',
          out: 'dark',
        }
      },

      tankObj: null,

      geo: {
        tank: null,
        pipe: null,
        sensor: null,
        lvo: null,
        lvw: null,
        oil: null,
        wtr: null,
        tm : null,
        roof: null,
        roofDeco: null,
        bottom: null
      },

      mat: {
        tank: null,
        pipe: null,
        sensor: null,
        lvo: null,
        lvw: null,
        oil: null,
        wtr: null,
        roof: null,
        roofDeco: null,
        bottom: null
      },

      mesh: {
        tank: null,
        pipe: null,
        sensor: null,
        oil: null,
        wtr: null,
        lvo: null,    // render_volume_2 에서만 사용함 (유위, 박스지오메트리)
        lvw: null,    // render_volume_2 에서만 사용함 (수위, 박스지오메트리)
        oilVol: null, // render_volume_2 에서만 사용함 (최종유량)
        wtrVol: null, // render_volume_2 에서만 사용함 (최종수분)
        roof: null,
        roofDeco: null,
        bottom: null
      },
      tankOpenAngle: getR(45),
      tankCloseAngle: getR(280),

      tmMater: {}, // 온도계 Mater
      tmMesh: {}, // 온도계 메쉬

      camPos: {x:0, y:0, z:0},

      roofH: 0,
      bottomH: 0,

      senH: 11,
      tankH: 9,
      tankR: 9,
      oilColor: 0,
      oilH: 0.0,
      wtrH: 0.0,
      nozSize: 0,

      oilVol: 0,
      wtrVol: 0,
      diffOvm: 0, // inout differences
      targetVol: 0, // inout differences
      inputTargetVol: null,
      volPercent: 0,


    }

  },
  created(){
    console.warn("--- TankLorry --- created---------------------");
    console.log( "oilCodes ----->", this.oilCodes );

  },



  async mounted() {
    console.warn(`--- TankLorry --- mounted---20240705--------: ${this.tank.tid} ----------`);
    try {
        console.log(`---20240705 TankLorry mounted for TID: ${this.tank.tid} ---`);
        this.w = this.width;
        this.h = this.height;

        this.isPacketFirst = true;
        this.isCamMoved = false;

        if (this.tank) {
            await this.getStatus();

            this.init(this.tank.tankShape);
            console.log(`20240705 Tank shape initialized: ${this.tank.tankShape}`);

            this.initCamera();
            this.loadObjects();
            this.initRenderer();
            this.initVolume();
        }
    } catch (err) {
        console.error(`20240705 Error in mounted for TID: ${this.tank.tid}`, err);
    }
  },



  methods: {




    initVolume(){

      console.warn( `------------------initVolume()------------`);

      // this.geo.oil.dispose();
      // this.mesh.oil.geometry.dispose();

      // constructor( width = 1, height = 1, depth = 1, widthSegments = 1, heightSegments = 1, depthSegments = 1 ) {
      const oilGeo = new THREE.BoxGeometry( this.maxW, this.maxH, this.maxD, );
      const oilMat = new THREE.MeshLambertMaterial({color: this.oilColor, side: THREE.DoubleSide, opacity: 0.1, transparent: true} );
      const oilMesh = new THREE.Mesh( oilGeo,  oilMat );

      this.geo.oil = oilGeo;
      this.mat.oil = oilMat;
      this.mesh.oil = oilMesh;

      oilMesh.position.set( 0, this.baseY, this.baseZ );

      oilMesh.updateMatrix();

      this.scene.add( this.mesh.oil );


      this.render();


    },

    /**
     * renderStock - 재고표시
     * @param tp
     */
    renderVolume(tp) {
      try{
        let oilH = (this.maxH * Number(tp.tvp)) / 100; // percent ex) (4 * 80.63) / 100
        let wtrH = 0;
        let oilY = 0;

        console.log( `TankLorry --- renderVolume--- [${tp.tid}]----> tankH=${this.tankH}, tankR=${this.tankR}, oilH=${oilH}, wtrH=${wtrH}, senH=${this.senH}`);

        this.geo.oil.dispose();
        // this.mesh.oil.dispose();
        // this.mat.oil.dispose();

        this.scene.remove( this.mesh.oil );


        const oilGeo = new THREE.BoxGeometry( this.maxW, oilH, this.maxD );
        this.mat.oil.opacity = 0.5;
        const oilMesh = new THREE.Mesh( oilGeo,  this.mat.oil );

        oilY = (this.maxH * -0.5) + (oilH * 0.5) + this.baseY;

        oilMesh.updateMatrix();

        this.geo.oil = oilGeo;
        this.mesh.oil = oilMesh;

        this.mesh.oil.position.set( 0, oilY, this.baseZ );

        console.warn( "TankLorry --- renderVolume --- maxH, tvp, oilH, oilY --->", this.maxH, tp.tvp, oilH, oilY );
        this.mat.oil.depthTest = false;

        this.mesh.oil.updateMatrix();

        this.scene.add( this.mesh.oil );
        // this.scene.add( this.mesh.tank );


        // this.render();

      }catch(err){
        console.error(err);
      }


    },


    /**
     * 온도계 Draw
     * @param sensorH 센서높이
     * @param tmSize 센서사이즈
     * @param tuneY 높이조절값
     */
    draw_thermometers(high, tmSize=0.5, tuneY=0 ){
      this.geo.tm    = new THREE.CylinderGeometry(this.nozSize, this.nozSize, tmSize, 36, 1);

      this.machine.temps.map(t=>{
        let tmKey = `tm${t.idx}`;
        let tlH = t.location * 0.001
        let tmH = -high * 0.5 + (tlH) + tuneY;

        this.tmMater[tmKey] = new THREE.MeshPhongMaterial({color: getHexColor(this.colors.tm.off), opacity: 1, transparent: false} );

        console.log( `render_volume_1_3()---[${this.tank.tid}]---draw_thermometers() --- tmKey = ${tmKey}, tmH = ${tmH}`);

        this.tmMesh[ tmKey ] = new THREE.Mesh( this.geo.tm,  this.tmMater[tmKey] );
        this.tmMesh[tmKey].position.set(0, tmH, 0);
        this.scene.add(this.tmMesh[tmKey]);
        console.log( this.tank.tid, 'scene added tmKey--->', tmKey );

      });
    },


    init(){
      // 1: Exclusive, 2: 실린더(옆으로누운), 3:Cone roof, 4, Tank lorry, 5, pipeline, 6,drum
      const tank = this.tank;
      console.log(`--- init Tank3D - TID : [${tank.tid}] ${this.shapeMap[tank.tankShape]}`)
      console.log( '######################----------',tank.tid, tank.tankShape,'------ ########################' );
      if(tank.machine===null){
        console.error(`--- init Tank3D - TID : [${tank.tid}] Machine Not found`);
        return;
      }

      this.tid = tank.tid;
      this.machine = tank.machine;

      this.senH = this.machine.sensorLen / 1000;
      this.tankH = tank.tankHeight / 1000;
      this.tankR = tank.tankWidth / 2 / 1000;

      let nozSize = this.nozSizeMap[ tank.nozSize ];
      this.nozSize = Number(nozSize.replace(/[^0-9]/g, "")) / 1000;
      this.tankColor = getHexColor( tank.color );
      this.oilColor = getHexColor( this.oilColors[tank.oilCode] )
      // console.log( 'tank-color =', tank.color, ', oil-color =', this.oilColors[tank.oilCode] );

      /*console.log( tank.tid,'senH    --->', this.senH )
      console.log( tank.tid,'tankH   --->', this.tankH )
      console.log( tank.tid,'tankR   --->', this.tankR )
      console.log( tank.tid,'nozSize --->', this.nozSize )*/


      this.container = this.$refs['container'];
      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(
        55, // 45가 눈이보는 시야각과 유사
        this.w / this.h,
        0.4,
        2000); // 이설정 바꾸지 말것

      this.camPos.x = 30;
      this.camPos.y = 40;
      this.camPos.z = 40;

      let cp = this.camPos;
      this.camera.position.set( cp.x , cp.y, cp.z );

      // this.camera.position.set( 0, 0, this.tankR+16 ); // 정면
      this.camera.lookAt( -4, 1, -2 );


      const light = new THREE.DirectionalLight( 0xFFFFFF, 1 );
      light.position.set(10,24,-5);
      this.scene.add( light );
      // this.scene.add( new THREE.AmbientLight(0x000000) );
      this.scene.background = new THREE.Color( getHexColor(this.bgColor) );//

    },

    loadObjects() {
    console.log(`20240705 Loading objects for TID: ${this.tank.tid}`);
    const loader = new GLTFLoader();
    loader.load('gltf/tanklorry.glb', (gltf) => {
        const tankObj = gltf.scene;
        tankObj.scale.set(0.03, 0.03, 0.03);
        tankObj.position.set(0, 0, 0);
        console.log('20240705 tankObj', tankObj );

        tankObj.traverse((child) => {
            if (child.isMesh) {
                child.material.color.set(0xf0f000);
                child.material.transparent = true;
                child.material.opacity = 0.3;

                tankObj.updateMatrix();
                this.mesh.tank = tankObj;
                this.scene.add(this.mesh.tank);

                this.setTextTest(this.tank.name);
            }
        });
        console.log(`20240705 Objects loaded for TID: ${this.tank.tid}`);
    }, undefined, (error) => {
        console.error('An error happened while loading the GLTF model:', error);
    });
    },

    initRenderer() {
      console.log(`20240705 Initializing renderer for TID: ${this.tank.tid}`);
      this.renderer = new THREE.WebGLRenderer({ antialias: true });
      this.renderer.setSize(this.w, this.h);
      this.renderer.sortObjects = false;
      this.container.appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.renderer.setAnimationLoop(this.animate);

      console.log(`20240705 Renderer initialized for TID: ${this.tank.tid}`);
    },

    animate: function() {
      // this.mesh.rotation.x += 0.01;
      // this.mesh.rotation.y += 0.02;
      this.render();

    },

    /**
     * 초기카메라 이동
     */
    initCamera() {

      // let tuneY = (shape==='2')? 2:0;
      const camera = this.camera
      gsap.to(camera.position, {
        duration: 1,
        repeat: 0,
        x: 8,
        y: 15,
        z: -20,
        onUpdate: () => {
          camera.lookAt(0, 0, 0);
        },
        onComplete: () => {}
      });

    },

    /**
     * 패킷 수신시 카메라 이동
     */
    moveCameraAction() {
      const camera = this.camera
      gsap.to(camera.position, {
        duration: 2,
        repeat: 0,
        x: 6,
        y: 9,
        z: -16,
        onUpdate: () => {
          camera.lookAt(-4, 1, -2);
        },
        onComplete: () => {}
      });

    },

    changeObjectColor: function (obj, color ){
      if(!obj) return;
      let colorHex = getHexColor(color)
      obj.color.set( colorHex );
      this.render();

    },

    /**
     * 센서 시그널 표시
     * @param type
     */
    sensorBlink: function(status='on'){
      this.changeObjectColor( this.mat.sensor,
        this.colors.sensor[status]? this.colors.sensor[status]:this.colors.sensor['unknown']
      );
      setTimeout(()=>{
        this.changeObjectColor( this.mat.sensor, this.colors.sensor.off );
      }, 500)
    },

    /**
     * 온도센서 시그널 표시
     * @param tmKey
     * @param type
     */
    tempBlink: function(tmKey, status='on'){
      // console.log( 'tmBlink()------', tmKey);
      this.changeObjectColor( this.tmMater[tmKey], this.colors.tm[status]? this.colors.tm[status]:this.colors.tm['unknown']);
      /*
      setTimeout(()=>{
        this.changeObjectColor( this.tmMater[tmKey], this.colors.tm.off );
      }, 800)
      */
    },


    /**
     * 입력 텍스트 드로우
     * @param title
     */
    setTextTest(title='') {
      const scene = this.scene;

      const senH = this.senH;

      const loader = new FontLoader();

      loader.load( '/fonts/NanumGothic_Bold.json', function ( font ) {
        console.log( font );

        const color = 0x0088AA;

        // const matDark = new THREE.LineBasicMaterial( {
        //   color: color,
        //   side: THREE.DoubleSide
        // } );

        const matLite = new THREE.MeshBasicMaterial( {
          color: color,
          transparent: false,
          opacity: 1,
          side: THREE.DoubleSide
        } );



        const atgShapes = font.generateShapes( title?title:'ATG Sensor', 0.6 );
        const geometry = new THREE.ShapeGeometry( atgShapes );
        geometry.computeBoundingBox();

        const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
        geometry.translate( xMid, senH/2+2.1, 0 );

        // make shape ( N.B. edge view not visible )
        const textMesh = new THREE.Mesh( geometry, matLite );
        textMesh.rotateY( PI );
        textMesh.position.z = -12;
        scene.add( textMesh );

      })

    },

    render(){
      // console.log(`Camera position: x=${this.camera.position.x}, y=${this.camera.position.y}, z=${this.camera.position.z}`);
      // console.log(`Camera lookAt: x=${this.controls.target.x}, y=${this.controls.target.y}, z=${this.controls.target.z}`);

       if(this.renderer) this.renderer.render( this.scene, this.camera );
    },

    destroyObjects(){
      console.log("############################## Tank3D beforeDestroy       ##############################");
      console.log("############################## Tank3D beforeDestroy Start ##############################");
      // Mesh 제거
      Object.keys( this.mesh ).map(k=>{
        if(this.mesh[k]) this.scene.remove( this.mesh[k] );
        // this.mesh[k].dispose();
      });

      // 온도계 Mesh 제거
      Object.keys( this.tmMesh ).map(k=>{
        if(this.tmMesh[k]) this.scene.remove( this.tmMesh[k] );
        // this.mesh[k].dispose();
      });

      // Geometry 제거
      Object.keys( this.geo ).map(k=>{
        if(this.geo[k]) this.geo[k].dispose();
      });

      // Mater 제거
      Object.keys( this.mat ).map(k=>{
        console.log( 'dispose matter --->', k )
        if(this.mat[k]) this.mat[k].dispose();
      });

      // 온도계 Mater 제거
      Object.keys( this.tmMater ).map(k=>{
        if(this.tmMater[k]) this.tmMater[k].dispose();
      });

      // this.renderer.forceContextLoss();
      this.renderer.setAnimationLoop(null);
      this.renderer.domElement = null;
      this.renderer.dispose();
      this.renderer = null;

      console.log("############################## Tank3D beforeDestroy End   ##############################");
    },

    async getStatus(){
      const rs = await apiCall('get', `/api/tank/status/${this.tank.tid}`);
      if(rs.code===200){
        this.tankSts = rs.result;
        this.sts = rs.result.io;
        this.statusMsg = IoStsMap[this.sts];

        console.log( 'tank status result -----> tid=',this.tid,'--->', rs.result  );
      }
      // await this.toastInfo(`입출고 상태 정보 업데이트`, 'info');
    },

    /**
     * call from parent component
     * @param data
     */
    setVolume(data){
      try {
        console.warn('TankLorry setVolume------------------- tid >>>>>>>>>>>>>>>>> ', data.tid);
        const tp = data.payload;

        console.warn('TankLorry setVolume------------------- packet >>>>>>>>>>>>>>>>>', data.payload);

        this.pk = tp;

        if (this.isPacketFirst) this.moveCameraAction( this.tank.tankShape );

        this.badgeColor = {'1':'dark', '2':'dark', '3':'dark', '4':'dark', '5':'dark', '6':'dark', '7':'dark', '8':'dark', '9':'dark'};
        this.status = 'on'

        if (tp.isEvt || tp.isErr) {
          this.status = 'warn';
          this.signalColor = tp.isErr ? 'danger' : 'warning';
          this.events.data = tp.events;
          this.events.dt = tp.dt;
        }else{
          this.events.data = [];
          this.events.dt = tp.dt;
          this.signalColor = 'info';
        }
        this.eventKey = random(1,10000);
        // console.log( 'TankLorry ----- events ------------> ', this.events );
        // console.log( 'TankLorry ----- signalColor ------------> ', this.signalColor );

        this.renderVolume(tp);

        this.setTankStatus( tp.sts );

        if( data.inout ) this.setInoutSts( data.inout );

        setTimeout(() => {
          this.signalColor = "dark"
        }, 500);
      }catch(err){
        console.log(err);
      }
    },

    setTankStatus(sts){
      this.tankSts = sts;
      const {io} = sts;
      console.log( 'setTankStatus --- inout ---> ',  sts);
      this.sts = io;
      this.statusMsg = IoStsMap[io];
      this.statusVariant = IoStsVariant[io];
    },

    setInoutSts(inout){
      console.log( 'setInoutSts ---------', inout );
      this.inout = inout;
      const {sts} = inout;

      if( sts==='N') return;

      switch(sts){
        case 'R':
          this.diffOvm = Math.abs(this.inout.outOvm); break;
        case 'D':
          this.diffOvm = this.inout.inOvm; break;
        default:
          break;
      }
    },

    async getInout(){
      const rs = await apiCall('get', `/api/inout/current/${this.tank.tid}`);
      if(rs.code===200){
        this.inout = rs.result;
        if( this.inout.sts!=='N') this.inputTargetVol = (this.volUnit==='g')? Math.round(this.inout.targetVol*LTR2GL):this.inout.targetVol;
        console.log( 'inout result -----> tid=',this.tid,'--->', rs.result  );
        this.sts = rs.result.sts;
      }
      // await this.toastInfo(`입출고 상태 정보 업데이트`, 'info');
    },

    progressVariant(v){
      if(!v) return 'dark';

      if(v<10) return 'info';
      else if(v < 40) return 'primary';
      else if(v < 80) return 'success';
      else if(v < 95) return 'warning';
      else if(v >= 95) return 'danger';
      else return 'info';
    },


    toggleDetail(){
      this.detailTmShow = !this.detailTmShow;
      /*
      this.windowHeight = window.outerHeight;
      this.windowWidth = window.outerWidth;
      if(this.detailTmShow){
        window.resizeTo( this.windowWidth, this.windowHeight+170)
      }else{
        window.resizeTo( this.windowWidth, this.windowHeight-170)
      }
      */
    },

    toggleUnit(){
      if(this.volUnit==='l') this.volUnit='g'
      else this.volUnit='l';

      if(this.lenUnit==='mm') this.lenUnit='i'
      else this.lenUnit='mm';

      if(this.tmpUnit==='c') this.tmpUnit = 'f';
      else this.tmpUnit='c';

    },

  },
  watch:{
    tlgPacket(newTp, oldTp) {
      console.log( 'new packet ---', newTp)
      console.log( 'old packet ---', oldTp)

      this.renderVolume( newTp );

    },
    'tank': function() {
      console.log( 'change tank --->', this.tank );
      this.init();
    }

  },
  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    this.destroyObjects();


  },



}
</script>
